import { formatDateString } from '@/utils/date-utils';
import { getNameOrEmail } from '@/utils/name-utils';
import { useAuth0 } from '@auth0/auth0-react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileMetadata } from '../../types';

/**
 * Document card for library or collection view
 *
 * n.b. the data contract for grouped search is quite different than not
 */
export const DocumentCard = ({ document }: { document: FileMetadata }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { collectionId, projectId } = useParams<{
    collectionId: string;
    projectId: string;
  }>();
  const { user } = useAuth0();
  // display date on card according to format stored in table
  const dataGridSavedState = JSON.parse(localStorage.getItem(`${user?.sub}-kf-${projectId}-gridState`) || '{}');
  const dateFormat = dataGridSavedState?.columns?.lookup?.displayDate?.description || 'day';

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      width={350}
      height={350}
      onClick={(event) => {
        event.stopPropagation();
        navigate(
          `/v2/knowledge-finder/${projectId}/collections/${collectionId ?? document.collections?.[0].uuid ?? document.documentCollectionId}/documents/${document.documentId ?? document.uuid}`
        );
      }}
      sx={{
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
          borderTop: `1px solid ${theme.palette.primary.contrastText}`,
          '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText
          }
        }
      }}
    >
      {/* Doc title */}
      <Typography
        variant="h4"
        sx={{
          mt: theme.spacing(2),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          height: 80
        }}
      >
        {document.displayName ?? document.title}
      </Typography>

      {/* Doc summary */}
      <Typography
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(2),
          color: theme.palette.gray.lightest,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 100,
          display: '-webkit-box',
          WebkitLineClamp: '4',
          WebkitBoxOrient: 'vertical'
        }}
        variant="body1"
      >
        {document.content || 'No summary.'}
      </Typography>

      {/* Doc metadata */}
      <Box sx={{ color: theme.palette.gray.lightest, mt: theme.spacing(1) }}>
        <Typography variant="subtitle2">
          {document.contact ??
            getNameOrEmail({
              email: document.uploadedBy.email,
              firstName: document.uploadedBy.firstName,
              lastName: document.uploadedBy.lastName
            })}
        </Typography>
        <Typography variant="subtitle2">Date: {formatDateString(document.createdAt, dateFormat)}</Typography>
        <Typography variant="subtitle2">Tags: {document.tags?.map((tag) => tag.name).join(', ')}</Typography>
      </Box>
      {/* Edit doc button */}
      {isHovered && (
        <Box
          position="absolute"
          sx={{ color: theme.palette.gray.lightest, mt: theme.spacing(1), mb: theme.spacing(1), bottom: 0, right: 0 }}
        >
          <IconButton
            disableRipple
            sx={{
              color: theme.palette.primary.lightest,
              ':hover': {
                background: theme.palette.primary.lightest,
                boxShadow: `0em 0em 0.4em 0em ${theme.palette.green.light}`
              }
            }}
            onClick={(event) => {
              event.stopPropagation();
              navigate(
                `/v2/knowledge-finder/${projectId}/collections/${collectionId ?? document.collections?.[0].uuid ?? document.documentCollectionId}/documents/${document.documentId ?? document.uuid}/settings`
              );
            }}
          >
            <ModeEditIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
